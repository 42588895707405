<div [ngClass]="{'hide-table':!showTable}">

    <app-table #proposalsList matSort [sort]="sort" matSortActive="updated_at" matSortDisableClear
        matSortDirection="desc" [dataSource]="dataSource" [displayedColumns]="displayedColumns"
        [searchField]="searchField" [filterFieldComponent]="filterFieldComponent" searchFieldName="search"
        filterFieldName="filters" [pageSizeOptions]="[10, 50, 100, 200]" [pageSize]="pageSize"
        (onTableUpdate)="onTableUpdate($event)" (onSelectedRow)="onSelectedRow($event)">

        <ng-container matColumnDef="image">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="id" class="text-center"
                style="width: 45px;margin-right: 5px;">#</th>
            <td mat-cell *matCellDef="let proposal" style="width: 45px;margin-right: 5px;">
                <div style="width: 45px;margin-right: 5px;">
                    <ngx-avatar [size]="40" [round]="true" initialsSize="2" [name]="proposal.client.name"
                        [src]="proposal.client.image">
                    </ngx-avatar>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="short_description">Orçamento</th>
            <td mat-cell *matCellDef="let proposal">
                <div fxLayout="column" fxLayoutAlign="start start">
                    <span class="proposal-title">#{{proposal.proposalNumber}}-{{proposal.shortDescription}}</span>
                    <span class="proposal-tags">
                        <div *ngFor="let tag of proposal.tags" class="tag"
                            [ngStyle]="{'background-color': tag?.hexadecimalColor ? tag?.hexadecimalColor : '#5ba5fe' }">

                            {{tag?.name}}

                        </div>
                    </span>
                    <span class="proposal-subtitle">
                        <span *ngIf="showClient" style="font-weight:600;">{{proposal.client.name}}</span> |
                        {{proposal.currentStatusDateName}} {{proposal.currentStatusDate | date:'d/MM/yy'}}
                    </span>

                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="criadapor">
            <th mat-header-cell *matHeaderCellDef class="text-left" fxHide fxShow.gt-xs>Criado por</th>
            <td mat-cell *matCellDef="let proposal" fxHide fxShow.gt-xs>
                <span class="text-boxed createdby" style="border-radius: 30px;" *ngIf="proposal">
                    {{ proposal.customer?.name }}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center" fxHide fxShow.gt-xs>Status</th>
            <td mat-cell *matCellDef="let proposal" fxHide fxShow.gt-xs>
                <span class="text-boxed" style="border-radius: 30px;" *ngIf="proposal" [ngClass]="proposal.statusClass">
                    {{ proposal.statusName }}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="valor">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="total_liquid" class="text-center" fxHide
                fxShow.gt-xs>Valor</th>
            <td mat-cell *matCellDef="let proposal" fxHide fxShow.gt-xs>
                <div fxLayout="row" fxLayoutAlign="center">
                    <!-- <div>
                        {{proposal.totalLiquid | currency:'R$ ':'symbol':'1.0-0'}}
                    </div> -->

                    <div class="total-payed">
                        <span style="white-space: nowrap;">R$
                            <strong>{{currencyFormatte(proposal.totalLiquid)}}</strong></span>
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="lastupdate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="updated_at" class="text-center" fxHide fxShow.gt-sm>
                Atualizado há</th>
            <td mat-cell *matCellDef="let proposal" fxHide fxShow.gt-sm>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <div class="proposal-subtitle" style="white-space: nowrap; padding: 0 5px;">
                        {{proposal.atualizadaEm}}
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="acoes">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let proposal" [ngClass.lt-lg]="'pr-8'">
                <div class="py-8">
                    <span>
                        <button id="proposal-item" class="btn-proposal-actions" mat-icon-button
                            [matMenuTriggerFor]="menuInOtherComponent.moreMenu" aria-label="More"
                            (click)="$event.stopPropagation();">
                            <mat-icon class="secondary-text">more_vert</mat-icon>
                        </button>
                    </span>

                    <app-proposal-actions [proposal]="proposal" #menuInOtherComponent
                        (onProposaChanged)="proposalsList.loadTable()"></app-proposal-actions>
                </div>
            </td>
        </ng-container>


    </app-table>
</div>

<div *ngIf="!showTable" class="empty-view">
    <div class="empty-view-title">
        Crie Seu Primeiro Orçamento
    </div>

    <div class="empty-view-subtitle">
        Você ainda não criou nenhum orçamento. Clique no botão abaixo e comece agora, é muito fácil!
    </div>

    <div class="container-btn">
        <button (click)="newProposal(true)">
            Criar Orçamento
        </button>
    </div>

    <div class="container-img">
        <img style="max-height: 303px;" src="/assets/images/ilustrations/first-proposal.png" alt="">
    </div>
</div>