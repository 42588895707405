import { Injectable } from '@angular/core';
import { ApiResponse, Log, User } from "app/entities";
import { ProposalLog } from 'app/entities/proposal-log';
import { ApiService } from "app/services/api.service";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UsersService extends ApiService {

    findAll(params: any): Observable<User[]> {
        params = this.paramsFromObject(params);
        return this.httpService.get(`/v1/clients/users`, params).pipe(
            map(res => User.parse(res.data) as User[])
        )
    }

    simpleUsuariosAll() {
        return this.httpService.get(`/v1/clients/customer/simple-customers`, {}).pipe(
            map(res => ApiResponse.parseWith(new User, res) as ApiResponse<User[]>)
        )
    }

    find(id: any) {
        return this.httpService.get(`/v1/clients/users/${id}`)
            .pipe(
                map(res => User.parse(res.data) as User)
            )
    }

    store(data: any) {
        let formData = this.formDataFromObject(data)
        return this.httpService.post(`/v1/clients/users`, formData)
            .pipe(
                map(res => User.parse(res.data) as User)
            )
    }

    update(id: number, data: any) {
        let formData = this.formDataFromObject(data)
        return this.httpService.post(`/v1/clients/users/${id}`, formData)
            .pipe(
                map(res => User.parse(res.data) as User)
            )
    }

    destroy(id: number) {
        return this.httpService.delete(`/v1/clients/users/${id}`)
    }

    requestNewPassword(id: number) {
        return this.httpService.post(`/v1/clients/password/${id}/request`)
    }

    changeCancellationStatus(id){
        return this.httpService.post(`/v1/clients/customer/${id}/cancel`, {})
    }

    changeRole(role, id){
        return this.httpService.post(`/v1/clients/customer/${id}/role`, {roleName: role})
    }


    findLogs(id, params) {
        return this.httpService.get(`/v1/clients/customer/${id}/logs`, params).pipe(
            map(res => ProposalLog.parse(res.data) as ProposalLog)
        )
    }
}
