import { Injectable } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { ApiResponse, Client } from 'app/entities';
import { map } from 'rxjs/operators';
import { Proposal } from 'app/entities/proposal';
import { ProposalLog } from 'app/entities/proposal-log';

@Injectable({
    providedIn: 'root'
})
export class ProposalService extends ApiService {


    storeLocally(proposal:Proposal){
        localStorage.setItem("copy_proposal", JSON.stringify(proposal))
    }

    getLocalStored(){
        return Proposal.parse(localStorage.getItem("copy_proposal")) || new Proposal()
    }

    findAll(params: any = {}) {
        params = this.paramsFromObject(params);
        return this.httpService.get(`/v1/clients/proposals`, params)
            .pipe(
                map(res => ApiResponse.parseWith(new Proposal, res) as ApiResponse<Proposal[]>)
            )
    }

    findAllClients() {
        return this.httpService.get(`/v1/clients/clients/simple-clients`, {})
    }

    preview(data: any) {
        let formData = this.formDataFromObject(data)
        return this.httpService.post(`/v1/clients/preview-proposal`, formData)
    }

    previewExample() {
        return this.httpService.post(`/v1/clients/preview-proposal/example`, {})
    }

    find(id: any) {
        return this.httpService.get(`/v1/clients/proposals/${id}`)
            .pipe(
                map(res => Proposal.parse(res.data) as Proposal)
            )
    }

    update(id: number, data: any) {
        let formData = this.formDataFromObject(data)
        return this.httpService.post(`/v1/clients/proposals/${id}`, formData)
            .pipe(
                map(res => Proposal.parse(res.data) as Proposal)
            )
    }

    aprove(id: number) {
        return this.httpService.post(`/v1/clients/proposals/${id}/approve`)
            .pipe(
                map(res => Proposal.parse(res.data) as Proposal)
            )
    }

    faturar(id: number) {
        return this.httpService.post(`/v1/clients/proposals/${id}/bill`)
            .pipe(
                map(res => Proposal.parse(res.data) as Proposal)
            )
    }

    pagar(id: number) {
        return this.httpService.post(`/v1/clients/proposals/${id}/pay`)
            .pipe(
                map(res => Proposal.parse(res.data) as Proposal)
            )
    }

    reprove(id: number) {
        return this.httpService.post(`/v1/clients/proposals/${id}/reprove`)
            .pipe(
                map(res => Proposal.parse(res.data) as Proposal)
            )
    }

    getProposalToDuplication(proposalToken){
        return this.httpService.get(`/v1/clients/proposals/${proposalToken}/duplicate`)
        .pipe(
            map(res => Proposal.parse(res.data) as Proposal)
        )
    }

    sendEmail(id: number, contact: any = null) {
        return this.httpService.post(`/v1/clients/proposals/${id}/email`, contact)
            .pipe(
                map(res => Proposal.parse(res.data) as Proposal)
            )
    }

    store(data: any) {
        let formData = this.formDataFromObject(data)
        return this.httpService.post(`/v1/clients/proposals`, formData)
            .pipe(
                map(res => Proposal.parse(res.data) as Proposal)
            )
    }

    destroy(id: number) {
        return this.httpService.delete(`/v1/clients/proposals/${id}`)
    }

    copyLink(id, source:any = null){
        return this.httpService.post(`/v1/clients/proposals/${id}/copy-link`, {
            source: source
        })
    }

    generatePDF(id){
        return this.httpService.post(`/v1/clients/proposals/${id}/generate/pdf/logs`, {})
    }

    findLogs(token){
        return this.httpService.get(`/v1/clients/proposals/${token}/logs`)
        .pipe(
            map(res => ProposalLog.parse(res.data) as ProposalLog)
        )
    }

}