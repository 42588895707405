import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenu } from "@angular/material/menu";
import { Router } from "@angular/router";
import { Proposal } from "app/entities/proposal";
import { ProposalService } from "app/main/pages/proposals/proposals.service";
import { AlertService } from "app/services/alert.service";
import { GtagService } from "app/services/gtag.service";
import { ProposalEventService } from "app/services/proposal.service";
import { SubscriptionRestrictionService } from "app/services/subscription-restriction.service";
import { environment } from "environments/environment";
import { ConfirmActionDialogComponent } from "../confirm-action-dialog/confirm-action-dialog.component";
import { PreviewProposalDialogComponent } from "../preview-proposal-dialog/preview-proposal-dialog.component";
import { ProposalLogsDialogComponent } from "../proposal-logs-dialog/proposal-logs-dialog.component";
import { Store } from "@ngrx/store";
import { loadProposalFirstSteps } from "app/state/account/account.actions";

@Component({
    selector: "app-proposal-actions",
    templateUrl: "./proposal-actions.component.html",
    styleUrls: ["./proposal-actions.component.scss"],
    exportAs: "menuInOtherComponent",
})
export class ProposalActionsComponent implements OnInit {
    @Input() proposal!: Proposal;
    @Input() editProposal = true;
    @ViewChild(MatMenu, { static: true }) moreMenu!: MatMenu;
    @Output("onProposaChanged") onProposalChanged = new EventEmitter<Proposal>()
    @Output("onProposaDeleted") onProposalDeleted = new EventEmitter<any>()

    env = environment

    constructor(
        public _matDialog: MatDialog,
        private _proposalsService: ProposalService,
        private _alertService: AlertService,
        private _router: Router,
        private _proposalEventService: ProposalEventService,
        private _subscriptionRestrictionService: SubscriptionRestrictionService,
        private _store:Store,
        private _gtag: GtagService,
    ) {}

    ngOnInit(): void {}

    edit() {
        this._router.navigate(["propostas", this.proposal.token]);
    }

    duplicateProposal() {
        if(!this._subscriptionRestrictionService.checkFeatureLimitAndShowDialog("propostas")){
            this._gtag.event('proposal_duplicated', {
                event_category: 'engagemnt',
                event_label: 'Orçamento duplicado'
            })

            this._router.navigate(["propostas", "copy"], {
                queryParams: {
                    proposal_token: this.proposal.token
                },
            });
        } 
    }

    aprovar() {
        this._proposalsService.aprove(this.proposal.id!).subscribe(
            (data) => {
                this._gtag.event('proposal_approved', {
                    event_category: 'engagemnt',
                    event_label: 'Orçamento aprovada'
                })
                this._proposalEventService.initOnNext();
                this.onProposalChanged.emit(data)
                this._store.dispatch(loadProposalFirstSteps())
                // this.dataSource?.load({});
            },
            (err) => {
                this._alertService.error(err);
            }
        );
    }

    faturar() {
        this._proposalsService.faturar(this.proposal.id!).subscribe(
            (data) => {
                this._gtag.event('proposal_invoiced', {
                    event_category: 'engagemnt',
                    event_label: 'Orçamento faturado'
                })
                this._proposalEventService.initOnNext();
                this.onProposalChanged.emit(data)
                this._store.dispatch(loadProposalFirstSteps())
                // this.dataSource?.load({});
            },

            (err) => {
                this._alertService.error(err);
            }
        );
    }

    pagar() {
        this._proposalsService.pagar(this.proposal.id!).subscribe(
            (data) => {
                this._gtag.event('proposal_payed', {
                    event_category: 'engagemnt',
                    event_label: 'Orçamento pago'
                })
                this._proposalEventService.initOnNext();
                this.onProposalChanged.emit(data)
                this._store.dispatch(loadProposalFirstSteps())
                // this.dataSource?.load({});
            },

            (err) => {
                this._alertService.error(err);
            }
        );
    }


    reprovar() {
        this._proposalsService.reprove(this.proposal.id!).subscribe(
            (data) => {
                this._gtag.event('proposal_refused', {
                    event_category: 'engagemnt',
                    event_label: 'Orçamento reprovado'
                })
                this._proposalEventService.initOnNext();
                this.onProposalChanged.emit(data)
                // this.dataSource?.load({});
            },
            (err) => {
                this._alertService.error(err);
            }
        );
    }

    openPDF() {
        var featureLimited = this._subscriptionRestrictionService.checkFeatureLimitAndShowDialog("pdf-propostas")
        if (!featureLimited) {
            var url = this.env.baseUrlHotsite + '/proposta/' + this.proposal.tokenLink + '.pdf'
            window.open(url, '_blank')
        }
    }


    sendWhatsapp(source, telefone: string) {
   
        
        this._proposalsService.copyLink(this.proposal.id, source).subscribe(
            (res) => {
                this._gtag.event('proposal_sent_whatsapp', {
                    event_category: 'engagemnt',
                    event_label: 'Orçamento enviado para o Whatsapp'
                })

                let link = res.data.link_token;

                if (this.proposal.status! <= 2) {
                    this.proposal.status = 2;
                    this.proposal.statusClass = "blue-800";
                    this.proposal.statusName = "Enviado";
                }
                
                
                const urlWhatsapp = `https://api.whatsapp.com/send?phone=+55${telefone.replace(
                    /\D/gim,
                    ""
                )}&text=${encodeURI(this.getMessageWithLinkProposal(link))}`;
                this._store.dispatch(loadProposalFirstSteps())
                window.open(urlWhatsapp, "_blank");
            },
            (error) => {
                this._alertService.error(error);
            }
        );
    }

    sendEmail(contact: any = null, contactEmail = null) {
        if (this.proposal?.client?.email == null) {
            return;
        }

        if (contact && !contactEmail) {
            contact = {
                contact_id: contact.id,
            };
        }
        if (contactEmail) {
            contact = {
                contact_email: contactEmail,
            };
        }

        this._proposalsService.sendEmail(this.proposal.id!, contact).subscribe(
            (data) => {
                this._gtag.event('proposal_sent_email', {
                    event_category: 'engagemnt',
                    event_label: 'Orçamento enviado para o e-mail'
                })
                this._alertService.success("Email enviado com sucesso.");
                this._proposalEventService.initOnNext();
                this.onProposalChanged.emit(data)
                this._store.dispatch(loadProposalFirstSteps())
                // this.dataSource?.load({});
            },
            (err) => {
                this._alertService.error(err);
            }
        );
    }

    preview() {
        this._gtag.event('proposal_preview', {
            event_category: 'engagemnt',
            event_label: 'Orçamento visualizado'
        })
        let dialog = this._matDialog.open(PreviewProposalDialogComponent, {
            panelClass: ["form-dialog", "large-form-dialog","proposal-preview-dialog"],
            data: {
                id: this.proposal?.id,
                token: this.proposal?.tokenLink,
            },
        });
        dialog.afterClosed().subscribe((result) => {});
    }

    confirmDeleteProposal() {
        let dialog = this._matDialog.open(ConfirmActionDialogComponent, {
            panelClass: ["form-dialog"],
            data: {
                title: "Excluir orçamento",
                message: `Tem certeza que deseja excluir o orçamento #${this.proposal.proposalNumber} - ${this.proposal.shortDescription}?`,
            },
        });
        dialog.afterClosed().subscribe((result) => {
            if (result === true) {
                this.deleteProposal();
            } else {
            }
        });
    }

    deleteProposal() {
        this._proposalsService.destroy(this.proposal.id!).subscribe(
            (_) => {
                this._gtag.event('proposal_delete', {
                    event_category: 'engagemnt',
                    event_label: 'Orçamento excluído'
                })
                this._proposalEventService.initOnNext();
                this.onProposalChanged.emit(this.proposal)
                this.onProposalDeleted.emit({})
                // this.dataSource?.load({});
            },
            (err) => {
                this._alertService.error(err);
            }
        );
    }

    copy(source) {
        this._proposalsService.copyLink(this.proposal.id, source).subscribe(
            (res) => {
                let link = res.data.link_token;
                this.copyToClipboard(this.getMessageWithLinkProposal(link));
                if (this.proposal.status! <= 2) {
                    this.proposal.status = 2;
                    this.proposal.statusClass = "blue-800";
                    this.proposal.statusName = "Enviado";
                }

                this._gtag.event('proposal_link_copied', {
                    event_category: 'engagemnt',
                    event_label: 'Link do orçamento copiado'
                })

                this._proposalEventService.initOnNext();

                this._alertService.success("Link copiado");

                this._store.dispatch(loadProposalFirstSteps())
            },
            (error) => {
                this._alertService.error(error);
            }
        );
    }

    showLogsProposals() {
        this._gtag.event('proposal_show_logs', {
            event_category: 'engagemnt',
            event_label: 'Mostrar logs do orçamento'
        })

        this._matDialog.open(ProposalLogsDialogComponent, {
            panelClass: ["form-dialog", "large-form-dialog"],
            data: {
                token: this.proposal?.token,
            },
        });
    }

    getMessageWithLinkProposal(link) {
        // let proposalUrl = environment.apiHost + "/email/proposals/" + this.proposal.token
        var data = new Date(this.proposal.dateProposal!);

        data.setDate(data.getDate() + this.proposal.expiresIn);
        var dia = data.getDate().toString().padStart(2, "0");
        var mes = (data.getMonth() + 1).toString().padStart(2, "0"); //+1 pois no getMonth Janeiro começa com zero.
        var ano = data.getFullYear();

        return `${this.proposal.proposalNumber} - ${
            this.proposal.shortDescription
        }
Orçamento válido até: ${dia + "/" + mes + "/" + ano}
Acesse o orçamento: ${link}`;
    }

    /*
     * Copia o texto passado por paramêtro para o clipboard.
     * @param {type} text
     * @returns {undefined|Boolean}
     */
    copyToClipboard(text) {
        if (
            document.queryCommandSupported &&
            document.queryCommandSupported("copy")
        ) {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy"); // Security exception may be thrown by some browsers.
            } catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return false;
            } finally {
                document.body.removeChild(textarea);
            }
        }
    }
}
