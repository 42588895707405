import { Decodable } from "./_decodable";
import { Item } from "./item";
import { Client } from "./client";
import { ProposalLog } from "./proposal-log";
import { UserSimple } from "./user";
import { AccountTag } from "./account-tag";

class Proposal extends Decodable {
    static readonly CREATED_ONLY = 1;
    static readonly SENT = 2;
    static readonly APROVED = 3;
    static readonly REFUSED = 4;
    static readonly BILLED = 5;
    static readonly PAYED = 6;
    static readonly DEFAULT_VALUE = 0;
    static readonly EXPIRED = 7;

    id?: number;
    clientId?: number;
    shortDescription?: string;
    termsAndConditions?: string;
    proposalDescription?: string;

    installments: number = 1;
    installmentValue: number = 0;

    deadlineDays?: number;
    expiresIn: number = 7;
    dateProposal?: Date;

    discountType: string = "raw";
    discountAmmount: number = 0;
    discountValue: number = 0;

    entryType: string = "raw";
    entryAmmount: number = 0;
    entryValue: number = 0;

    freteValue: number = 0;
    freteInfo?: string;

    juros:number = 0;

    totalLiquid: number = 0;
    totalGross: number = 0;

    canProcess: boolean = false;

    status?: number;
    statusName?: string;
    statusClass?: string;

    items?: Item[];
    tags?: AccountTag[];
    client?: Client;
    logs?: ProposalLog[];

    token: any;
    tokenLink?: string;
    proposalNumber? : number;
    createdAt?: Date;
    updatedAt?: Date;
    hasInstallments?: boolean;

    currentStatusDateName?:string
    currentStatusDate?:Date

    customer?:UserSimple

    protected _map = {
        createdAt: (e) => this.parseDate(e),
        updatedAt: (e) => this.parseDate(e),
        dateProposal: (e) => this.parseDate(e),
        currentStatusDate: (e) => this.parseDate(e),
        logs: (e) => ProposalLog.parse(e),
        items: (e) => Item.parse(e),
        tags: (e) => AccountTag.parse(e),
        client: (e) => Client.parse(e),
        customer: (e) => UserSimple.parse(e),
    };
}

export { Proposal };
