import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { Proposal } from "app/entities/proposal";
import { FilterFieldComponent } from "app/layout/components/table/table.component";
import { ProposalsDatasource } from "app/main/pages/proposals/proposals-datasource";
import { GtagService } from "app/services/gtag.service";
import { SubscriptionRestrictionService } from "app/services/subscription-restriction.service";
import { take } from "rxjs/operators";


@Component({
    selector: "app-proposals-list",
    templateUrl: "./proposals-list.component.html",
    styleUrls: ["./proposals-list.component.scss"],
    animations: fuseAnimations,
})
export class ProposalsListComponent implements OnInit {
    @ViewChild(MatSort, {static: true}) sort!: MatSort;
    @Input("proposalsDataSource") dataSource!: ProposalsDatasource;
    @Input("searchField") searchField?: ElementRef;
    @Input("filterFieldComponent") filterFieldComponent?: FilterFieldComponent;

    @Input("showClient") showClient = true;
    @Input("showCustomer") showCustomer = false;
    @Input("pageSize") pageSize = null;
    @Input("proposalFilter") proposalFilter = {};

    @Output("onFilterChange") onFilterChange = new EventEmitter<any>() 

    displayedColumns = [
        "image",
        "description",
        "criadapor",
        "status",
        "valor",
        "lastupdate",
        "acoes",
    ];

    showTable = true;
    constructor(
        public _matDialog: MatDialog,
        private _router: Router,
        private _subscriptionRestrictionService: SubscriptionRestrictionService,
        private _gtag: GtagService
    ) { }

    ngOnInit() {
        if (!this.showCustomer) {
            this.displayedColumns.splice(2, 1);
        }
        if (null == this.dataSource) {
            throw new Error("proposalsDataSource not set!");
        }

        this.dataSource.noRecords.pipe(take(2)).subscribe((res) => {
            if (res) {
                this.showTable = false;
            }
        })
    }

    onTableUpdate(params: any) {
        this.onFilterChange.emit(params)
        this.dataSource?.load(params);
    }

    onSelectedRow(item: Proposal) {
        this._router.navigate(["propostas", item.token]);
    }

    openAddDialog() {
        this._router.navigate(["propostas", "new"]);
    }

    newProposal(fromEmptyView: boolean = false) {
        if(!this._subscriptionRestrictionService.checkFeatureLimitAndShowDialog("propostas")){
            if (fromEmptyView) {
                //CRIANDO PROPOSTA A PARTIR DA EMPTYVIEW
                this._gtag.event('empty_view_begin_proposal_create', {
                    event_category: "engagemnt",
                    event_label: "Dispara ao clicar no botão Criar Orçamento na empty view da tabela de orçamentos"
                })
            }
            this._router.navigate(['/propostas/new'])
        } 
    }

    currencyFormatte(value) {
        return new Intl.NumberFormat().format(value)
    }
}
