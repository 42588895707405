<button #filterMenuTrigger="matMenuTrigger" class="btn btn-filter" fxLayout="row" fxLayoutAlign="center center"
    [matMenuTriggerFor]="proposalFilter">
    <mat-icon>filter_alt</mat-icon>
    <span class="pl-4 btn-filtro-text">
        {{buttonName}}
        <span *ngIf="(filterCount|async) as numberOfFilters" class="filters-badge">{{numberOfFilters}}</span>
    </span>
</button>
<mat-menu class="filter-menu" #proposalFilter="matMenu">
    <div class="card-filter" id="card-filter" (click)="$event.stopPropagation()">

        <div class="card-filter-header">
            <div class="card-filter-header-title">
                {{cardTitle}}
            </div>
        </div>

        <div class="card-filter-content" *ngIf="menuOpen">
            <form [formGroup]="form" (keydown.tab)="$event.stopPropagation()" (submit)="apply()" fxLayout="column">
                <div fxFlex="100" fxLayout="column">
                    <div class="card-filter-name">Status</div>
                    <mat-form-field appearance="outline" class="chips-select">
                        <mat-select formControlName="status" multiple placeholder="Selecione uma opção">

                            <mat-select-trigger>
                                <mat-chip-list>
                                    <mat-chip class="status-chip" *ngFor="let stat of statusControl.value"
                                        [removable]="true" (removed)="onStatusRemoved(stat)">
                                        {{ statusItems[stat] }}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </mat-chip-list>
                            </mat-select-trigger>

                            <mat-option *ngFor="let item of statusItems | keyvalue"
                                value="{{item.key}}">{{item.value}}</mat-option>

                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxFlex="100" fxLayout="column" *ngIf="showTags">
                    <div class="card-filter-name  mt-8">Tag</div>
                    <mat-form-field appearance="outline" class="chips-select">
                        <mat-select formControlName="tags" multiple placeholder="Selecione uma opção">

                            <mat-select-trigger>
                                <mat-chip-list>
                                    <mat-chip class="status-chip" *ngFor="let tagId of tagsControl.value"
                                        [removable]="true" (removed)="onTagRemoved(tagId)">
                                        {{ (getTagById(tagId) | async)?.name }}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </mat-chip-list>
                            </mat-select-trigger>

                            <mat-option *ngFor="let tag of tags$ | async" [value]="tag.id">
                                {{tag.name}}
                            </mat-option>

                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxFlex fxLayout="column" class="mt-8">
                    <mat-form-field class="naked-form-field" *ngIf="showFormControl('date_filter')">
                        <mat-select formControlName="date_filter" aria-required="Esse campo é obrigatorio" mdInput
                            panelClass="panelResponsive">
                            <mat-option *ngFor="let dateFilter of dateFilterFields" [value]="dateFilter.field">
                                <span>
                                    {{
                                    dateFilter.name
                                    }}
                                </span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="10px">

                    <mat-form-field appearance="outline" fxFlex *ngIf="showFormControl('start_date')">
                        <mat-label>Início</mat-label>
                        <input [matDatepicker]="dateStart" (focus)="dateStart.open()" appScrollOnFocus
                            autocomplete="off" matInput formControlName="start_date" fxFlex>
                        <button type="button" matSuffix mat-icon-button
                            (click)="form.controls.start_date.value && setFormControlValue($event,'start_date',null)">
                            <mat-icon>{{form.controls.start_date.value?'close':'date_range'}}</mat-icon>
                        </button>
                        <mat-datepicker #dateStart></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex *ngIf="showFormControl('end_date')">
                        <mat-label>Fim</mat-label>
                        <input [matDatepicker]="dateEnd" (focus)="dateEnd.open()" appScrollOnFocus autocomplete="off"
                            matInput formControlName="end_date" fxFlex>
                        <button type="button" matSuffix mat-icon-button
                            (click)="form.controls.end_date.value && setFormControlValue($event,'end_date',null)">
                            <mat-icon>{{form.controls.end_date.value?'close':'date_range'}}</mat-icon>
                        </button>
                        <mat-datepicker #dateEnd></mat-datepicker>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="10px">

                    <mat-form-field appearance="outline" fxFlex *ngIf="showFormControl('report_start_date')">
                        <mat-label>Início</mat-label>
                        <input [matDatepicker]="reportDateStart" (focus)="reportDateStart.open()" appScrollOnFocus
                            autocomplete="off" matInput formControlName="report_start_date" fxFlex>
                        <button type="button" matSuffix mat-icon-button
                            (click)="form.controls.report_start_date.value && setFormControlValue($event,'report_start_date',null)">
                            <mat-icon>{{form.controls.report_start_date.value?'close':'date_range'}}</mat-icon>
                        </button>
                        <mat-datepicker #reportDateStart></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex *ngIf="showFormControl('report_end_date')">
                        <mat-label>Fim</mat-label>
                        <input [matDatepicker]="reportDateEnd" (focus)="reportDateEnd.open()" appScrollOnFocus
                            autocomplete="off" matInput formControlName="report_end_date" fxFlex>
                        <button type="button" matSuffix mat-icon-button
                            (click)="form.controls.report_end_date.value && setFormControlValue($event,'report_end_date',null)">
                            <mat-icon>{{form.controls.report_end_date.value?'close':'date_range'}}</mat-icon>
                        </button>
                        <mat-datepicker #reportDateEnd></mat-datepicker>
                    </mat-form-field>
                </div>

                <div fxFlex="100" fxLayout="column" *ngIf="showClients">
                    <div class="card-filter-name  mt-8">Cliente</div>
                    <mat-form-field appearance="outline">
                        <mat-label>Todos clientes</mat-label>

                        <mat-select #tagSelect formControlName="client_id" placeholder="Cliente *"
                            aria-required="Esse campo é obrigatorio" mdInput panelClass="panelResponsive">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="bankFilterCtrl" [placeholderLabel]="'Buscar...'"
                                    [noEntriesFoundLabel]="'Nenhum cliente encontrado'" style="height: 80px;">
                                </ngx-mat-select-search>
                            </mat-option>

                            <mat-option *ngFor="
                                            let client of clientsFiltered
                                                | async
                                        " [value]="client.id">
                                <span>
                                    {{
                                    client.name
                                    }}
                                </span>
                            </mat-option>
                        </mat-select>
                        <a *ngIf="tagSelect.value" matSuffix mat-icon-button aria-label="Clear"
                            (click)="setFormControlValue($event,'client_id',null)">
                            <mat-icon>close</mat-icon>
                        </a>
                    </mat-form-field>
                </div>

                <div fxFlex="100" fxLayout="column" *ngIf="showClients">
                    <div class="card-filter-name  mt-8">Usuário</div>
                    <mat-form-field appearance="outline">
                        <mat-label>Todos usuários</mat-label>

                        <mat-select #usuariosSelect formControlName="customer_id" placeholder="Usuário *"
                            aria-required="Esse campo é obrigatorio" mdInput panelClass="panelResponsive">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="usuariosFilterCtrl"
                                    [placeholderLabel]="'Buscar...'" [noEntriesFoundLabel]="'Nenhum usuário encontrado'"
                                    style="height: 80px;">
                                </ngx-mat-select-search>
                            </mat-option>

                            <mat-option *ngFor="let usuario of usuariosFiltered | async" [value]="usuario.id">
                                <span>
                                    {{
                                    usuario.name
                                    }}
                                </span>
                            </mat-option>
                        </mat-select>
                        <a *ngIf="usuariosSelect.value" matSuffix mat-icon-button aria-label="Clear"
                            (click)="setFormControlValue($event,'customer_id',null)">
                            <mat-icon>close</mat-icon>
                        </a>
                    </mat-form-field>
                </div>


                <div class="card-filter-name" *ngIf="showFormControl('start_value')">Valor do orçamento</div>
                <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field appearance="outline" fxFlex="48" *ngIf="showFormControl('start_value')">
                        <mat-label>A partir de R$</mat-label>
                        <input [options]="{
                                prefix: 'R$',
                                thousands:
                                    '.',
                                precision:0,
                                align: 'left'
                            }" inputmode="numeric" autocomplete="off" currencyMask matInput type="text"
                            formControlName="start_value">
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="48" *ngIf="showFormControl('end_value')">
                        <mat-label>Até R$</mat-label>
                        <input [options]="{
                                prefix: 'R$',
                                thousands:
                                    '.',
                                precision:0,
                                align: 'left'
                            }" inputmode="numeric" autocomplete="off" currencyMask matInput type="text"
                            formControlName="end_value">
                    </mat-form-field>
                </div>


                <div class="card-btns-container  mt-8">
                    <button mat-button class="btn btn-reset" type="button" (click)="reset()">Limpar</button>
                    <button mat-button class="btn btn-apply" type="submit">Aplicar</button>
                </div>
            </form>
        </div>

    </div>
</mat-menu>