import { AfterViewInit, Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { DatePipe } from '@angular/common';
import { BehaviorSubject, from, Observable, ReplaySubject, Subject } from 'rxjs';
import { Client } from 'app/entities';
import { ProposalService } from 'app/main/pages/proposals/proposals.service';
import { map, takeUntil } from 'rxjs/operators';
import { StringFilterByPipe } from 'app/string-filter-by.pipe';
import { FilterFieldComponent } from 'app/layout/components/table/table.component';
import { MatMenuTrigger } from '@angular/material/menu';
import * as moment from 'moment';
import { CustomerState } from 'app/state/customer/customer.reducer';
import { Store } from '@ngrx/store';
import { selectCustomers } from 'app/state/customer/customer.select';
import { loadCustomers } from 'app/state/customer/customer.actions';
import { UsersService } from 'app/main/pages/users/users.service';
import { AccountState } from 'app/state/account/account.reducer';
import { selectAccount, selectTags } from 'app/state/account/account.selectors';
import { Account } from 'app/entities/account';
import { AccountTag } from 'app/entities/account-tag';

@Component({
    selector: 'app-proposals-filter',
    templateUrl: './proposals-filter.component.html',
    styleUrls: ['./proposals-filter.component.scss']
})
export class ProposalsFilterComponent implements OnInit, FilterFieldComponent, AfterViewInit {

    @ViewChild('filterMenuTrigger') filterMenuTrigger!: MatMenuTrigger;
    @Output() onFilter = new Subject();
    @Output("onFiltered") onFiltered = new EventEmitter<any>();
    @Input("saveFilters") saveFilters = false
    @Input("savedFiltersKey") savedFiltersKey = 'saved-filters'
    @Input("showClients") showClients = true
    @Input("showTags") showTags = true

    @Input("buttonName") buttonName = "Filtros"
    @Input("cardTitle") cardTitle = "Opções de Filtros"
    @Input("hideFields") hideFields: string[] = [
        'report_start_date',
        'report_end_date',
    ]

    customers$: Observable<CustomerState> = this.store.select(selectCustomers);

    tags$: Observable<AccountTag[]> = new Observable<AccountTag[]>(observer => {
        this.storeAccount.select(selectTags).subscribe(tags => {
            observer.next(tags)
        })
    });

    filters: any = {};
    form!: FormGroup;
    clientsFiltered: ReplaySubject<Client[]> = new ReplaySubject<Client[]>(1);
    usuariosFiltered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filtersUpdated: BehaviorSubject<any> = new BehaviorSubject(null);

    public dateFilterFields = [
        {
            "name": "Data de criação",
            "field": "created_at"
        },
        {
            "name": "Data de envio",
            "field": "sented_at"
        },
        {
            "name": "Data de aprovação",
            "field": "approved_at"
        },
        {
            "name": "Data de pagamento",
            "field": "paid_at"
        }
    ]

    clients
    usuarios

    public bankFilterCtrl: FormControl = new FormControl();
    public usuariosFilterCtrl: FormControl = new FormControl();
    private _onDestroy = new Subject<void>();

    menuOpen = false

    statusItems = {
        1: "Criado",
        2: "Enviado",
        3: "Aprovado",
        4: "Reprovado",
        5: "Faturado",
        6: "Pago",
        7: "Expirado",
    }



    constructor(
        private _formBuilder: FormBuilder,
        private _proposalsService: ProposalService,
        private stringFilterByPipe: StringFilterByPipe,
        private userService: UsersService,
        private store: Store<CustomerState>,
        private storeAccount: Store<AccountState>,
    ) { }


    ngAfterViewInit(): void {
        this.filterMenuTrigger.menuOpened.subscribe(() => {
            this.menuOpen = true
        })

        this.filterMenuTrigger.menuClosed.subscribe(() => {
            if (this.form.dirty) {
                this.apply()
            }

        })
    }



    ngOnInit(): void {

        if (this.showClients) {
            this._proposalsService.findAllClients().subscribe((clients) => {
                this.clients = clients.data;
                this.clientsFiltered.next(this.clients.slice());

            });
        }


        this.bankFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterClients();
            });

        this.usuariosFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterUsuarios();
            });

        this.createForm();
        this.loadCustomers()
    }


    loadCustomers() {
        this.userService.simpleUsuariosAll().subscribe((customers) => {
            this.usuarios = customers.data;
            this.usuariosFiltered.next(this.usuarios.slice());
        })
    }

    createForm() {
        let formControlsValues = {
            status: [],
            tags: [],
            date_filter: "created_at",
            start_date: null,
            end_date: null,
            report_start_date: null,
            report_end_date: null,
            client_id: null,
            start_value: null,
            end_value: null,
            customer_id: null,
        }
        // remove fields that should be hidden
        this.hideFields.forEach(field => {
            delete formControlsValues[field]
        })

        this.form = this._formBuilder.group(formControlsValues)

        if (this.saveFilters) {
            var savedfilters = localStorage.getItem(this.savedFiltersKey);
            if (savedfilters) {
                this.filters = JSON.parse(savedfilters)
                // remove fields that should be hidden
                this.hideFields.forEach(field => {
                    delete this.filters[field]
                })
                this.setDefaultValues()
                this.form.patchValue(this.filters)
                if (this.getFilterCount() > 0) {
                    this.filtersUpdated.next(null)
                }
            }
        }
    }

    filterCount: Observable<number> = from(this.filtersUpdated).pipe(
        map(() => {
            return this.getFilterCount()
        })
    )

    getFilterCount() {
        return Object.keys(this.filters).filter(key => key != 'date_filter').reduce((result, current) => {
            if (this.filters[current] == undefined || this.filters[current] == "") {
                return result
            } else if (Array.isArray(this.filters[current])) {
                return result + this.filters[current].length
            }
            return result + 1
        }, 0)
    }


    onStatusRemoved(status) {
        const statuses = this.statusControl.value as string[];
        this.removeFromArray(statuses, status);
        this.setFormControlValue(null, 'status', statuses);
    }

    onTagRemoved(tagId) {
        const tags = this.tagsControl.value as AccountTag[];
        this.removeFromArray(tags, tagId);
        this.setFormControlValue(null, 'tags', tags);
    }

    private removeFromArray<T>(array: T[], toRemove: T): void {
        const index = array.indexOf(toRemove);
        if (index !== -1) {
            array.splice(index, 1);
        }
    }

    get statusControl() {
        return this.form.controls?.status
    }

    get tagsControl() {
        return this.form.controls?.tags
    }

    getTagById(tagId): Observable<AccountTag | undefined> {
        return this.tags$.pipe(
            map(tags => tags.find(tag => tag.id == tagId))
        )
    }

    setFormControlValue($event, controlName: string, value: any) {
        $event?.stopPropagation()
        if (this.form.controls[controlName]) {
            this.form.controls[controlName].setValue(value)
            this.form.markAsDirty()
        }

        this.setDefaultValues()
    }

    setDefaultValues() {
        console.log("0", this.filters);
        Object.keys(this.filters).forEach(key => {
            if (key == 'report_start_date' && !this.filters[key]) {
                console.log('setting default report_start_date');

                this.form.controls[key].setValue(moment().subtract(1, 'months'))
                this.filters[key] = moment().subtract(1, 'months').format('YYYY-MM-DD')
            }

            if (key == 'report_end_date' && !this.filters[key]) {
                this.form.controls[key].setValue(moment())
                this.filters[key] = moment().format('YYYY-MM-DD')
            }
        })

        if (this.saveFilters) {
            console.log("salvando filtros", this.filters)
            localStorage.setItem(this.savedFiltersKey, JSON.stringify(this.filters));
        }

    }

    apply(): void {
        //ONLY APPLY FILTER IF FORM IS DIRTY
        if (this.form.dirty) {

            //switch start and end intervals if both are set, and min max are in wrong positions
            //correct for valor
            if (this.form.controls?.start_value?.value && this.form.controls?.end_value?.value) {
                if (this.form.controls?.start_value.value > this.form.controls?.end_value.value) {
                    //switch values
                    var start = this.form.controls?.start_value.value
                    this.form.controls?.start_value.setValue(this.form.controls?.end_value.value)
                    this.form.controls?.end_value.setValue(start)
                }
            }

            //correct for date
            if (this.form.controls?.start_date?.value) {
                this.form.controls?.start_date.setValue(moment(this.form.controls?.start_date?.value))
            }
            if (this.form.controls?.end_date?.value) {
                this.form.controls?.end_date.setValue(moment(this.form.controls?.end_date?.value))
            }
            if (this.form.controls?.report_start_date?.value) {
                this.form.controls?.report_start_date.setValue(moment(this.form.controls?.report_start_date?.value))
            }
            if (this.form.controls?.report_end_date?.value) {
                this.form.controls?.report_end_date.setValue(moment(this.form.controls?.report_end_date?.value))
            }
            if (this.form.controls?.start_date?.value && this.form.controls?.end_date?.value) {
                if (this.form.controls?.start_date?.value.isAfter(this.form.controls?.end_date?.value)) {
                    //switch values
                    var start = this.form.controls?.start_date.value
                    this.form.controls?.start_date.setValue(this.form.controls?.end_date.value)
                    this.form.controls?.end_date.setValue(start)
                }
            }
            if (this.form.controls?.report_start_date?.value && this.form.controls?.report_end_date?.value) {
                if (this.form.controls?.report_start_date?.value.isAfter(this.form.controls?.report_end_date?.value)) {
                    //switch values
                    var start = this.form.controls?.report_start_date.value
                    this.form.controls?.report_start_date.setValue(this.form.controls?.report_end_date.value)
                    this.form.controls?.report_end_date.setValue(start)
                }
            }

            this.filters = {
                ...this.form.value,
                // start_date: this.formatDate(this.form.value.start_date),
                // end_date: this.formatDate(this.form.value.end_date),
                start_date: this.form.value.start_date?.format('YYYY-MM-DD') ?? null,
                end_date: this.form.value.end_date?.format('YYYY-MM-DD') ?? null,
                report_start_date: this.form.value.report_start_date?.format('YYYY-MM-DD') ?? null,
                report_end_date: this.form.value.report_end_date?.format('YYYY-MM-DD') ?? null,
            }

            // console.log(this.filters);


            this.filtersUpdated.next(null)

            //save filters
            if (this.saveFilters) {
                localStorage.setItem(this.savedFiltersKey, JSON.stringify(this.filters));
            }

            this.form.markAsPristine();
            this.form.markAsUntouched();

            this.onFilter.next(this.filters)

            if (this.onFiltered) {
                this.onFiltered.emit(this.filters)
            }
        }
        if (this.filterMenuTrigger.menuOpen) {
            this.filterMenuTrigger.closeMenu()
        }

        this.onFiltered.emit(this.filters);

    }

    reset() {
        this.form.reset()
        this.form.markAsDirty()
        // TODO: reset date filter to created_at nao funciona, da um erro que o filtro nao existe, verificar inpacto de colocar safe null aqui
        this.form.controls['date_filter']?.setValue('created_at')
        this.setDefaultValues()
        this.apply()
        this.filterMenuTrigger.closeMenu()
    }

    filterClients() {
        if (!this.clients) {
            return;
        }
        // get the search keyword
        let search = this.bankFilterCtrl.value;
        if (!search) {
            this.clientsFiltered.next(this.clients.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        this.clientsFiltered.next(
            this.stringFilterByPipe.transform(this.clients, search, "name")
        );
    }

    filterUsuarios() {
        if (!this.usuarios) {
            return;
        }
        // get the search keyword
        let search = this.usuariosFilterCtrl.value;
        if (!search) {
            this.usuariosFiltered.next(this.usuarios.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        this.usuariosFiltered.next(
            this.stringFilterByPipe.transform(this.usuarios, search, "name")
        );
    }

    showFormControl(controlName: string) {
        if (this.hideFields.includes(controlName)) {
            return false
        }
        return true
    }
}

